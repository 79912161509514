<template>
    <div class="Breadcrumb-view">
        <div>当前位置：</div>
        <div @click="toHome()"> 首页</div>
        <div @click="handelTwo()" v-if="$route.query.type">- {{ nowType() }}</div>
        <div v-if="specific && $route.query.moduleId">- {{ specific }}</div>
    </div>
</template>

<script>
    export default {
        name: 'Breadcrumb',
        data() {
            return {
                specific: null,
                name: null,
            }
        },
        mounted() {
            // this.$store.dispatch('getLegalAdviceData', this.setSpecific)
            // this.$store.dispatch('setSmartInstruments', this.setSpecific)
            this.$store.dispatch('getHomePageData', this.setSpecific)
        },
        watch: {
            $route() {
                this.setSpecific()
            },
        },
        methods: {
            nowType() {
                switch (this.$route.query.type) {
                    case '1':
                        return '智能咨询'
                    case '2':
                        return '文书生成'
                    case '3':
                        return '案件计算器'
                }
            },
            handelTwo() {
                if (this.$route.name == 'ReasonList') {
                    this.$store.commit('setlegalAdvice')
                } else {
                    this.$router.push({
                        name: 'ReasonList',
                        query: {
                            type: this.$route.query.type,
                        },
                    })
                }
            },
            toHome() {
                this.$router.push({
                    name: 'Home',
                })
            },
            setSpecific() {
                if (this.$route.query.type) {
                    if (this.$route.query.type == '3') {
                        let name
                        switch (this.$route.name) {
                            case 'LegalFare':
                                name = '诉讼费计算器'
                                break
                            case 'InjuryOnTheJob':
                                name = '工伤纠纷计算器'
                                break
                            case 'Damage':
                                name = '人身损害赔偿计算器'
                                break
                            case 'Traffic':
                                name = '交通肇事赔偿计算器'
                                break
                        }
                        this.specific = name
                        this.$store.commit('setSpecific', name)
                    } else {
                        for (let item of this.$route.query.type == 1
                            ? this.$store.state.legalAdvice
                            : this.$store.state.smartInstruments) {
                            if (item.childrenModules == null) {
                                if (this.$route.query.moduleId == item.moduleId) {
                                    this.specific = item.moduleName
                                    this.$store.commit('setSpecific', item.moduleName)
                                }
                            } else {
                                for (let label of item.childrenModules) {
                                    if (this.$route.query.moduleId == label.moduleId) {
                                        this.specific = label.moduleName
                                        this.$store.commit('setSpecific', item.moduleName)
                                    }
                                }
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped lang="less">
    .Breadcrumb-view {
        display: flex;
        padding: 0 0.5rem;
        div,
        a {
            cursor: pointer;
            margin-left: 0.1rem;
            color: #fff;
            font-size: 0.18rem;
        }
    }
</style>
