<template>
    <div class="header">
        <div class="logo-view">
            <img src="@/assets/img/logo.png" alt="" />
            <div class="titleFont"></div>
        </div>
        <div class="breadcrumb">
            <a-button color="#358CF3" shape="round" icon="home" @click="goHome"> 首页 </a-button>
            <div class="breadcrumb-main">
                <Breadcrumb></Breadcrumb>
            </div>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/PC/header/Breadcrumb'

    export default {
        name: 'Header',
        mounted() {},
        components: {
            Breadcrumb,
        },
        data() {
            return {
                nowAdd: '',
            }
        },
        methods: {
            goHome() {
                this.$router.replace({
                    name: 'Home',
                })
            },
        },
    }
</script>

<style scoped lang="less">
    .header {
        width: 100%;
        height: 0.86rem;
        background-color: #0043a7;
        display: flex;
        align-items: center;
        padding-left: 0.4rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        .logo-view {
            flex: 1;
            display: flex;
            align-items: center;

            img {
                width: 0.46rem;
                height: 0.46rem;
                margin-right: 0.17rem;
            }

            .titleFont {
                background: url('../../../assets/img/title.png');
                background-size: 100%;
                width: 2.45rem;
                height: 0.34rem;
            }
        }

        .breadcrumb {
            display: flex;
            align-items: center;
            height: 100%;

            .ant-btn {
                background-color: #358cf3;
                border-color: #358cf3;
                color: #fff;
                width: 1.36rem;
                height: 0.46rem;
                font-size: 0.18rem;
            }

            .breadcrumb-main {
                margin-left: 0.51rem;
                height: 100%;
                border-left: 1px solid rgba(255, 255, 255, 0.1);
                display: flex;
                align-items: center;
                font-size: 0.18rem;
                color: #fff;
            }
        }
    }
</style>
