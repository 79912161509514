<template>
    <div class="footer">
        <div class="border-view"> </div>
        <div class="main"> Copyright © 长春市把手科技有限公司 </div>
        <div class="border-view"> </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        data() {
            return {}
        },
    }
</script>

<style scoped lang="less">
    .footer {
        height: 0.53rem;
        display: flex;
        align-items: flex-end;
    }

    .main {
        padding: 0 0.1rem;
        color: rgba(255, 255, 255, 0.5);
        font-size: 0.18rem;
        flex: 1;
        text-align: center;
        line-height: 0.53rem;
        transform: translateY(-12px);
    }

    .border-view {
        height: 0.39rem;
        width: 39vw;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
</style>
